/* eslint @typescript-eslint/no-explicit-any: off */

/**
 * USAGE EXAMPLES:
 *
 * ```ts
 * // If no base URI is provided, it will default
 * // to the current host followed by '/api'.
 * const apiUriBuilder = new ApiUriBuilder('http://example.com/api');
 *
 * const url = apiUriBuilder.resolve('creatives', {
 *     filter: {
 *       size: '300x250',
 *       runDate: {
 *         from: '01/01/2001',
 *         to: '10/10/2020'
 *       },
 *     }
 *     sort: 'adNumber',
 *     pagination: { current_page: 1, per_page: 20 },
 * });
 * ```
 */

import type { JsonApiQuery } from '@dmp/shared/json-api-request';
import { stringifyQuery } from '@dmp/shared/json-api-request';
import type { DeepPartial } from '@dmp/shared/types';
import { absoluteUrl } from './absolute-url';

export class ApiUriBuilder {
    private baseUri: string;

    constructor(baseUri?: string) {
        if (baseUri) {
            this.baseUri = baseUri.replace(/\/$/, '');
            return;
        }

        this.baseUri = absoluteUrl('/api');
    }

    /**
     * Build a URL from a JSON:API query.
     */
    public resolve<Params extends JsonApiQuery>(
        path: string,
        params?: DeepPartial<Params>
    ): string {
        const qs = params !== undefined ? stringifyQuery(params) : '';
        const endpoint = `${path}${qs}`;

        return `${this.baseUri}/${endpoint}`;
    }

    /**
     * Build a URL from a set of query param key-values.
     */
    public resolveStandard<Params extends Record<string, any>>(
        path: string,
        params?: Params
    ) {
        const qs =
            params !== undefined ? this.stringifyStandardParams(params) : '';

        const endpoint = `${path}${qs}`;

        return `${this.baseUri}/${endpoint}`;
    }

    private stringifyStandardParams<Params extends Record<string, any>>(
        params: Params
    ) {
        const fields = Object.entries(params)
            .map(([key, value]) => {
                const stringifiedValue =
                    typeof value === 'string' ? value : JSON.stringify(value);

                return `${encodeURIComponent(key)}=${encodeURIComponent(
                    stringifiedValue
                )}`;
            })
            .join('&');

        return `?${fields}`;
    }
}
