import { useColorMode } from '@chakra-ui/react';

export const useIsDark = () => {
    const { colorMode } = useColorMode();

    return {
        colorMode,
        isDark: colorMode === 'dark',
    };
};
