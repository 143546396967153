import { z } from 'zod';

/**
 * Non-empty strings must be a valid email address.
 */
export const emptyStringOrEmail = () => z.string().email().or(z.literal(''));

export type FormidableFile = z.infer<ReturnType<typeof formidableFile>>;

export const formidableFile = (params?: {
    invalid_type_error?: string;
    required_error?: string;
}) =>
    z.object(
        {
            size: z.number().int(),
            filepath: z.string(),
            originalFilename: z.string().nullable(),
            // This is a randomly-generated string. Do not use if original file name is important.
            newFilename: z.string(),
            mimetype: z.string().nullable(),
            mtime: z.date().nullable().optional(),
            hashAlgorithm: z.union([
                z.literal(false),
                z.literal('sha1'),
                z.literal('md5'),
                z.literal('sha256'),
            ]),
            hash: z.string().nullable().optional(),
            toString: z.function().returns(z.string()),
            toJSON: z.function().returns(formidableFileJSON()),
        },
        params
    );

export const formidableFileJSON = () =>
    z.object({
        size: z.number().int(),
        filepath: z.string(),
        originalFilename: z.string().nullable(),
        hash: z.string().nullable().optional(),
        length: z.number(),
        mimetype: z.string().nullable(),
        mtime: z.date().nullable(),
        newFilename: z.string(),
    });

/**
 * A valid domain name (ie. `hamsterdance.com`, `xn--bcher-kva.example`).
 */
export const domain = () =>
    z
        .string()
        .regex(
            /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
            'Must be a valid domain name'
        );
