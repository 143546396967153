import type { ValueOfRecord } from '@dmp/shared/types';

/**
 * The name of all the CustomEvents in the global DocumentEventMap.
 */
type CustomEventName = ValueOfRecord<{
    [K in keyof DocumentEventMap]: DocumentEventMap[K] extends CustomEvent
        ? K
        : never;
}>;

/**
 * The Payload of a CustomEvent on the global DocumentEventMap.
 */
type Detail<T extends CustomEventName> = DocumentEventMap[T]['detail'];

/**
 * Dispatch an CustomEvent on the Document.
 *
 * Examples:
 *
 * ```ts
 * dispatchGlobalEvent('rowHighlight', { id: 'asdf' });
 * dispatchGlobalEvent('eventWithNoPayload');
 * ```
 */
export function dispatchGlobalEvent<
    T extends CustomEventName,
    D extends Detail<T>
>(eventName: T, ...eventData: D extends void ? [] : [D]) {
    const [detail] = eventData;

    const event = new CustomEvent(eventName, {
        detail,
    });

    document.dispatchEvent(event);
}
