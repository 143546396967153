/* eslint-disable @typescript-eslint/no-explicit-any */

import { z } from 'zod';

export function resourceID<T extends string>(type: T) {
    return z.object({
        type: z.literal(type),
        id: z.string(),
    });
}

/**
 * A singular JSON API relationship definition.
 */
export function relationship<T extends z.ZodObject<any>>(data: T) {
    return z.object({ data: data.nullable() });
}

/**
 * An array JSON API relationship definition.
 */
export function relationships<T extends z.ZodObject<any>>(data: T) {
    return z.object({ data: z.array(data).nullable() });
}

export const pagination = z.object({
    current_page: z.number().positive(),
    count: z.number().gte(0),
    total: z.number().gte(0),
    per_page: z.number().positive(),
    total_pages: z.number().positive(),
});

export type Pagination = z.infer<typeof pagination>;
