import { filters, parseQuery } from '@dmp/shared/json-api-request';
import { z } from 'zod';

export const entriesFilter = filters({
    data: {
        // Not using a `combined` type, because
        // we are going to manually search JSON data.
        type: 'like',
    },
});

/**
 * Using a custom validator instead of `createJsonApiQueryValidator()`,
 * since we don't know the exact shape until we load up the Feed Schema.
 */
export const entriesQueryValidator = z.preprocess(
    parseQuery,
    z.object({
        filter: z
            .object({
                data: z.string().optional(),
            })
            .default({}),
        sort: z
            .array(
                z.object({
                    field: z.string(),
                    direction: z.union([z.literal('ASC'), z.literal('DESC')]),
                })
            )
            .optional(),
        page: z
            .object({
                number: z.number().positive().default(1),
                size: z.number().positive().max(200).default(100),
            })
            .optional(),
    })
);

export type EntriesQuery = z.infer<typeof entriesQueryValidator>;
