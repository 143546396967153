import { truthy } from '@dmp/shared/helpers';
import type { PartialJsonApiQuery } from './types';

/**
 * Translate a partial JSON:API query into URL query string.
 */
export const stringifyQuery = (query: PartialJsonApiQuery): string => {
    const fields = stringifyFields(query.fields);
    const filter = stringifyFilter(query.filter);
    const include = stringifyInclude(query.include);
    const page = stringifyPage(query.page);
    const sort = stringifySort(query.sort);

    const queryPieces = [fields, filter, include, page, sort].filter(truthy);

    if (queryPieces.length === 0) {
        return '';
    }

    return `?${queryPieces.join('&')}`;
};

/**
 * In:
 *
 * ```
 * {
 *     user: ['firstName', 'lastName'],
 *     address: ['street'],
 * }
 * ```
 *
 * Out:
 * `fields[user]=firstName,lastName&fields[address]=street`
 */
const stringifyFields = (
    fields: PartialJsonApiQuery['fields']
): string | undefined => {
    if (fields == undefined) {
        return undefined;
    }

    return Object.entries(fields)
        .map(([field, values]) => {
            if (values === undefined) {
                return undefined;
            }

            return `fields[${field}]=${values.join(',')}`;
        })
        .filter(truthy)
        .join('&');
};

/**
 * In:
 *
 * ```
 * {
 *     one: 'one love',
 *     two: true,
 * }
 * ```
 *
 * Out:
 * `filter[one]=one+love&filter[two]=true`
 */
const stringifyFilter = (
    filter: PartialJsonApiQuery['filter']
): string | undefined => {
    if (filter === undefined) {
        return undefined;
    }

    return Object.entries(filter)
        .filter(([_key, value]) => value !== undefined && value !== '')
        .map(([key, value]) => {
            const stringValue =
                typeof value === 'string' ? value : JSON.stringify(value);

            return `filter[${encodeURIComponent(key)}]=${encodeURIComponent(
                stringValue || ''
            )}`;
        })
        .join('&');
};

const stringifyInclude = (
    include: PartialJsonApiQuery['include']
): string | undefined => {
    if (include === undefined) {
        return undefined;
    }

    const pieces: string[] = [];

    for (const includePiece of include) {
        if (includePiece === undefined) {
            continue;
        }
        pieces.push(encodeURIComponent(includePiece));
    }

    const includes = pieces.join(',');

    return `include=${includes}`;
};

/**
 * In:
 *
 * ```
 * {
 *     page: {
 *         number: 1,
 *         size: 2
 *     }
 * }
 * ```
 *
 * Out:
 * `page[number]=1&page[size]=2`
 */
const stringifyPage = (
    page: PartialJsonApiQuery['page']
): string | undefined => {
    if (page === undefined) {
        return undefined;
    }

    const number = page.number && `page[number]=${page.number}`;
    const size = page.size && `page[size]=${page.size}`;

    return [number, size].filter(truthy).join('&');
};

/**
 * In:
 *
 * ```
 * [
 *     { field: 'one', direction: 'ASC' },
 *     { field: 'one', direction: 'DESC' },
 * ]
 * ```
 *
 * Out:
 *
 * `sort=one,-two`
 */
const stringifySort = (
    sort: PartialJsonApiQuery['sort']
): string | undefined => {
    if (sort === undefined) {
        return undefined;
    }

    const pieces: string[] = [];

    for (const sortPiece of sort) {
        if (sortPiece === undefined) {
            continue;
        }
        const directionIndicator = sortPiece.direction === 'ASC' ? '' : '-';
        pieces.push(`${directionIndicator}${sortPiece.field}`);
    }

    const fields = pieces.join('&');

    return `sort=${fields}`;
};
