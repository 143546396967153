import { z } from 'zod';
import { pagination } from '@dmp/shared/zod-utils';
import { entryResourceObjectValidator } from './entries-resource-objects';
import { feedResourceObjectValidator } from './feeds-resource-objects';
import { userResourceObjectValidator } from './users-resource-objects';

const feedIncludes = z.array(
    z.discriminatedUnion('type', [
        entryResourceObjectValidator,
        userResourceObjectValidator,
    ])
);

export const showFeedResponseValidator = z.object({
    data: feedResourceObjectValidator,
    included: feedIncludes.optional(),
});

export type ShowFeedResponse = z.infer<typeof showFeedResponseValidator>;

export const listFeedsResponseValidator = z.object({
    data: z.array(feedResourceObjectValidator),
    included: feedIncludes.optional(),
    meta: z.object({
        pagination: pagination,
    }),
});

export type ListFeedsResponse = z.infer<typeof listFeedsResponseValidator>;
