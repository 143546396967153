/**
 * Transforms Json request to an `application/x-www-form-urlencoded` accepted format.
 *
 * @param jsonData
 * @returns {string}
 */
export const transformRequest = (
    jsonData: Record<string, string | number | boolean>
): string =>
    Object.entries(jsonData)
        .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join('&');

/**
 * Turn an array of object's with an `id` property,
 * and map them by that ID.
 *
 * Example:
 *
 * ```ts
 * const result = mapById([
 *     { id: '1', name: 'Mike' },
 *     { id: '2', name: 'Ike' },
 * ]);
 *
 * // Same as:
 * const result2 = {
 *     '1': { id: '1', name: 'Mike' },
 *     '2': { id: '2', name: 'Ike' },
 * }
 * ```
 */
export const mapById = <T extends { id: string }>(
    input: T[]
): Record<string, T> =>
    input.reduce(
        (carrier, coreQuestion) => ({
            ...carrier,
            [coreQuestion.id]: coreQuestion,
        }),
        {} as Record<string, T>
    );

/**
 * Generate simple id.
 */
export const generateSimpleId = (prefix: string = '', length: number = 8) => {
    const pre = prefix.length > 0 ? `${prefix}-` : '';
    return `${pre}${Math.random()
        .toString(36)
        .slice(2, length + 2)}`;
};

/**
 * Generate random number id
 */
export const generateNumberId = (length: number = 7): string =>
    length > 0
        ? Math.random()
              .toString()
              .slice(2, length + 2)
        : '';
