import { z } from 'zod';
import type { EntryData, FilterMode } from './types';

export function columnTypeValidator() {
    return z.union([
        z.literal('boolean'),
        z.literal('number'),
        z.literal('string'),
        z.literal('url'),
    ]);
}

export function anyEntryData(): z.ZodType<EntryData> {
    return z.record(z.union([z.string(), z.number(), z.boolean()]));
}

export function filterMode(): z.ZodType<FilterMode> {
    return z.union([z.literal('all'), z.literal('any')]);
}
