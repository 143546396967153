import { feedSchemaValidator } from '@dmp/feedr/models';
import { z } from 'zod';
import { entryResourceIdentifierValidator } from './entries-resource-objects';
import { userResourceIdentifierValidator } from './users-resource-objects';

export const feedResourceIdentifierValidator = z.object({
    type: z.literal('feeds'),
    id: z.string().min(1),
});

export type FeedResourceIdentifier = z.infer<
    typeof feedResourceIdentifierValidator
>;

export const feedResourceObjectValidator = z.object({
    type: z.literal('feeds'),
    id: z.string().min(1),
    attributes: z
        .object({
            name: z.string().min(1),
            schema: feedSchemaValidator,
            createdAt: z.string().min(1),
            updatedAt: z.string().min(1),
        })
        .required(),
    relationships: z.object({
        entries: z.object({
            data: z.array(entryResourceIdentifierValidator).nullable(),
        }),
        updatedBy: z.object({
            data: userResourceIdentifierValidator.nullable(),
        }),
    }),
});

export type FeedResourceObject = z.infer<typeof feedResourceObjectValidator>;
