/**
 * @file This is simple `window.fetch` wrapper. It's main
 *     function is mostly just to make it easier to mock in
 *     tests (see `auth-gate.spec.ts` for example). But hopefully
 *     we can expand it in the future to make it easier to
 *     fit our application-specific use cases.
 */

import type {
    Error as JsonApiError,
    ResponseWithErrors,
} from '@dmp/shared/types';

export const fatch = async <T = unknown>(
    url: string,
    config: RequestInit = {}
): Promise<T> => {
    const defaultConfig: RequestInit = {
        method: 'GET',
    };

    const response = await fetch(url, { ...defaultConfig, ...config });
    const responseType = response.headers.get('content-type') || '';

    const data = responseType.includes('application/json')
        ? await response.json()
        : await response.text();

    if (response.ok) {
        return data;
    } else {
        throw new RequestError(data, response);
    }
};

export class RequestError<T = unknown> extends Error {
    data: T;
    response: Response;

    constructor(data: T, response: Response) {
        const message = typeof data === 'string' ? data : 'Request error';
        super(message);

        this.data = data;
        this.response = response;
        this.name = 'RequestError';
    }
}

/**
 * Normalize different error shapes into JsonApiError array for consistency.
 */
export function normalizeError(error: unknown): JsonApiError[] {
    const unwrapped = error instanceof RequestError ? error.data : error;

    if (typeof unwrapped === 'string') {
        return [{ detail: unwrapped }];
    } else if (isJsonApiError(unwrapped)) {
        return unwrapped.errors;
    } else if (unwrapped instanceof Error) {
        return [{ detail: unwrapped.message }];
    } else {
        return [{ detail: 'Unknown error' }];
    }
}

const isJsonApiError = (error: unknown): error is ResponseWithErrors => {
    return (
        typeof error === 'object' &&
        error !== null &&
        'errors' in error &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Array.isArray((error as any).errors)
    );
};
