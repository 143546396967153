/**
 * Calculate previous pages based on current page
 *
 * @param current {Integer} - current page
 * @param halfSpan {Integer} - number of pages prior to the current
 * @return result {Array} - Array of the previous pages in ascending order
 *
 */
export const calcPreviousPages = (
    current: number,
    halfSpan: number = 2
): number[] => {
    const result = [];
    const reachable = current - halfSpan;
    const adjustedReachable = reachable > 0 ? reachable : 1;
    let i = adjustedReachable;

    while (i < current) {
        result.push(i);
        i += 1;
    }

    return result;
};

/**
 * Calculate next pages based on current page
 *
 * @param current {Integer} - current page
 * @param totalPages {Integer} - total pages
 * @param halfSpan {Integer} - number of page span after the current
 * @return result {Array} - Array of the next pages in ascending order
 *
 */
export const calcNextPages = (
    current: number,
    totalPages: number,
    halfSpan: number = 2
): number[] => {
    const result = [];
    const reachable = current + halfSpan;
    const adjustedReachable = reachable < totalPages ? reachable : totalPages;
    let i = current + 1;

    while (i <= adjustedReachable) {
        result.push(i);
        i += 1;
    }

    return result;
};

/**
 * Conditional gate before executing goToPage
 *
 * @param pageNum page to go to
 * @param current current page
 * @param total total pages
 */
export const allowToGo = (
    pageNum: number,
    current: number,
    total: number
): boolean => current !== pageNum && pageNum >= 1 && pageNum <= total;

/**
 * Condition to show the start page (page 1) - ex: 1.. 4 5
 *
 * @param current current page
 * @param halfSpan radius of pages from the current page.
 */
export const showStart = (current: number, halfSpan: number): boolean =>
    current - halfSpan > 1;

/**
 * Condition to show the last page - ex: 4 5 .. 10
 *
 * @param current current page
 * @param total total pages
 * @param halfSpan radius of pages from the current page.
 */
export const showEnd = (
    current: number,
    total: number,
    halfSpan: number
): boolean => current + halfSpan < total;
