import { uniq, concat } from 'remeda';

/**
 * Concat two lists, ensuring all resulting values are unique.
 */
export const appendUnique =
    <T>(additions: T[]) =>
    (currentList: T[] = []): T[] =>
        uniq(concat(currentList, additions));

/**
 * Insert items into a list at a specific index.
 */
export function insert<T>(list: T[], index: number, items: T[]): T[] {
    return [...list.slice(0, index), ...items, ...list.slice(index)];
}

/**
 * Move an item from one index to another.
 */
export function move<T>(list: T[], oldIndex: number, newIndex: number): T[] {
    const item = list[oldIndex];
    const withoutItem = list.filter((_, index) => index !== oldIndex);
    return insert(withoutItem, newIndex, [item]);
}

/**
 * Remove values from a list.
 */
export function without<T>(list: T[], toOmit: T[]): T[] {
    return list.filter((item) => !toOmit.includes(item));
}

/**
 * Set the item at a given index.
 */
export function replaceItem<T>(list: T[], index: number, item: T): T[] {
    return [...list.slice(0, index), item, ...list.slice(index + 1)];
}
