import { anyEntryData } from '@dmp/feedr/models';
import { z } from 'zod';

export const entryResourceIdentifierValidator = z.object({
    type: z.literal('entries'),
    id: z.string().min(1),
});

export type EntryResourceIdentifier = z.infer<
    typeof entryResourceIdentifierValidator
>;

export const entryResourceObjectValidator = z.object({
    type: z.literal('entries'),
    id: z.string().min(1),
    attributes: z
        .object({
            data: anyEntryData(),
            createdAt: z.string().min(1),
            updatedAt: z.string().min(1),
        })
        .required(),
});

export type EntryResourceObject = z.infer<typeof entryResourceObjectValidator>;
