import { anyEntryData } from '@dmp/feedr/models';
import { z } from 'zod';

export const updateEntryRequestValidator = z
    .object({
        data: z.object({
            type: z.literal('entries'),
            id: z.string().min(1),
            attributes: z.object({
                data: anyEntryData(),
            }),
        }),
    })
    .required();

export type UpdateEntryRequest = z.infer<typeof updateEntryRequestValidator>;

export const createEntryRequestValidator = z
    .object({
        data: z.object({
            type: z.literal('entries'),
            attributes: z.object({
                data: anyEntryData(),
            }),
        }),
    })
    .required();

export type CreateEntryRequest = z.infer<typeof createEntryRequestValidator>;
