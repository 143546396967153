import type { InputProps } from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export const TrimmedInput = forwardRef<HTMLInputElement, InputProps>(
    ({ onBlur, onChange, ...rest }, ref) => {
        const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
            // trim value and assign to input dom
            const trimmedValue = e.target.value.trim();
            e.target.value = trimmedValue;

            // trigger the new trimmed value
            onChange && onChange(e);
            onBlur && onBlur(e);
        };
        return (
            <Input
                {...rest}
                onBlur={handleOnBlur}
                onChange={onChange}
                ref={ref}
            />
        );
    }
);
