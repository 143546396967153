import { Center, Text } from '@chakra-ui/react';
import { fetchCurrentUser } from '@dmp/feedr/data-access';
import { RequestError } from '@dmp/shared/client-utils';
import { useQuery } from '@tanstack/react-query';
import type { ReactElement } from 'react';
import * as React from 'react';
import { CURRENT_USER } from '../../config/query-keys';
import { useCurrentUser } from '../../hooks/user-current-user';

interface AuthGateProps {
    children?: React.ReactNode;

    // Disable querying the API. Used mostly for testing.
    disableFetch?: boolean;

    // How long until we show the "Authenticating" message? Helps prevent
    // the message from showing for a unnecessarily short time.
    loadingMessageDelay?: number;
}

export const AuthGate = ({
    children,
    disableFetch,
    loadingMessageDelay = 2000,
}: AuthGateProps) => {
    const [currentUser, setCurrentUser] = useCurrentUser();

    const [hasWaited, setHasWaited] = React.useState(
        loadingMessageDelay === 0 ? true : false
    );

    const state = currentUser
        ? 'authenticated'
        : hasWaited
        ? 'authenticating'
        : 'idle';

    React.useEffect(() => {
        if (state === 'authenticated' || loadingMessageDelay === 0) {
            return;
        }

        const timeout = setTimeout(
            () => setHasWaited(true),
            loadingMessageDelay
        );

        return () => clearTimeout(timeout);
    }, [loadingMessageDelay, state]);

    useQuery([CURRENT_USER], fetchCurrentUser, {
        enabled: !disableFetch,

        // Retry on Proxy error. Most likely, we are in a
        // dev environment and waiting on the API to start.
        retry: (_retryCount, error) =>
            error instanceof RequestError && error.response.status === 504,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) =>
            setCurrentUser({ id: data.id, ...data.attributes }),
        onError: () => window.location.assign('/auth/saml'),
    });

    switch (state) {
        case 'authenticated':
            return children as ReactElement;

        case 'authenticating':
            return (
                <Center height="100vh">
                    <Text fontSize="3xl">Authenticating</Text>
                </Center>
            );

        case 'idle':
            return null;
    }
};

export default AuthGate;
