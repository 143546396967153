import { parse as parseQueryString } from 'qs';

/**
 * Allow an un-processed query string to be passed in.
 * This is mostly for NextJS, which makes parsing nested
 * query strings a pain.
 */
export function preprocessQuery(input: unknown): unknown {
    if (typeof input === 'string') {
        const pieces = input.split('?');

        return pieces.length > 1
            ? parseQueryString(pieces[1])
            : parseQueryString(pieces[0]);
    }

    return input;
}

export function preprocessSort(input: unknown): unknown {
    if (typeof input !== 'string') {
        return input;
    }

    return input.split(',').map((f) => {
        const field = f.replace(/^-/, '');
        const direction = f.startsWith('-') ? 'DESC' : 'ASC';

        return { field, direction };
    });
}
