export class InvalidQueryStringParameter extends Error {
    protected _field: string;

    public constructor(field: string, details: string) {
        super(`Invalid Query Parameter (${field}): ${details}`);

        this._field = field;
    }

    public field(): string {
        return this._field;
    }
}
