import type { ColumnSchema } from '@dmp/feedr/models';
import {
    anyEntryData,
    columnTypeValidator,
    feedSchemaValidator,
} from '@dmp/feedr/models';
import { formidableFile } from '@dmp/shared/zod-utils';
import { z } from 'zod';

const FILE_SIZE = 5 * 1024 * 1024; // 5MB
const SUPPORTED_FORMATS = ['text/csv', 'text/tsv', 'text/tab-separated-values'];

export const createFeedRequestValidator = z
    .object({
        data: z.object({
            type: z.literal('feeds'),
            id: z.string().optional(),
            attributes: z.object({
                name: z.string().min(1),
                schema: feedSchemaValidator.optional(),
                entries: z.array(anyEntryData()),
            }),
        }),
    })
    .required();

export const importFeedRequestValidator = z.object({
    file: formidableFile({ required_error: 'A file is required' })
        .refine((value) => value.size <= FILE_SIZE, 'File too large')
        .refine(
            (value) =>
                value.mimetype && SUPPORTED_FORMATS.includes(value.mimetype),
            'Unsupported Format'
        ),
});

export type CreateFeedRequest = z.infer<typeof createFeedRequestValidator>;

export interface ColumnSchemaUpdate extends ColumnSchema {
    oldName?: string;
}

export type FeedSchemaUpdate = Array<ColumnSchemaUpdate>;

/**
 * Extension of `feedSchemaValidator` that supports
 * `oldName` property for renaming properties.
 */
const feedSchemaUpdateValidator = z.array(
    z.object({
        name: z.string().min(1),
        oldName: z.string().optional(),
        type: columnTypeValidator(),
    })
);

export const updateFeedRequestValidator = z
    .object({
        data: z.object({
            type: z.literal('feeds'),
            id: z.string().optional(),
            attributes: z.object({
                name: z.string().optional(),
                schema: feedSchemaUpdateValidator.optional(),
                entries: z.array(anyEntryData()).optional(),
            }),
        }),
    })
    .required();

export type UpdateFeedRequest = z.infer<typeof updateFeedRequestValidator>;
