import type { ComponentSingleStyleConfig } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { darken, lighten, meetsContrastGuidelines } from 'polished';

export const makeButton = (
    primary: string,
    secondary: string,
    darkModeDarkenLevel: number
): ComponentSingleStyleConfig => {
    return {
        variants: {
            primary: makeVariant(primary, darkModeDarkenLevel),
            secondary: makeVariant(secondary, darkModeDarkenLevel),
        },
    };
};

const makeVariant =
    (color: string, darkModeDarkenLevel: number) =>
    (props: StyleFunctionProps) => {
        const darkModeBg = darken(darkModeDarkenLevel, color);
        const background = mode(color, darkModeBg)(props);

        // find text color contrast against the background
        const darkTextColor = '#222222';
        const lightTextColor = '#FFFFFF';
        const meetContrast = meetsContrastGuidelines(
            darkTextColor,
            background
        ).AAA;

        const textColor = meetContrast ? darkTextColor : lightTextColor;

        return {
            background,
            color: textColor,
            _hover: {
                background: mode(
                    darken(0.05, background),
                    lighten(0.1, background)
                )(props),
            },
        };
    };
