import { makeTheme } from '@dmp/shared/components';

export const themeInfo = makeTheme({
    baseColors: {
        primary: '#1665cf',
    },
    darkModeDarkenLevel: 0,
    globalStyles: (colorMode) => ({
        'html, #root': {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        body: {
            width: '100%',
            height: '100%',
            background: colorMode === 'light' ? 'gray.50' : 'gray.900',
        },
    }),
});
