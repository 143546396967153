import { jsonApiQuery, filters } from '@dmp/shared/json-api-request';

export const feedsFilter = filters({
    name: { type: 'like' },
    combined: { type: 'like', field: 'name' },
});

export const feedsQueryValidator = jsonApiQuery()
    .filter(feedsFilter)
    .sort(['name', 'createdAt', 'updatedAt'])
    .include(['entries', 'updatedBy'])
    .paginate();

export type FeedsQuery = typeof feedsQueryValidator._output;
