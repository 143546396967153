import { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { TRPCError } from '@trpc/server';

import { RequestError } from '@dmp/shared/client-utils';
import type { ResponseWithErrors } from '@dmp/shared/types';

export const useErrorToaster = () => {
    const toast = useToast();

    const handleError = (error: unknown, duration: number = 6000) => {
        const msgs = convertErrorToElements(error);

        toast({
            title: 'Error',
            status: 'error',
            duration,
            isClosable: true,
            description: msgs,
        });
    };

    return {
        handleError,
    };
};

export const convertErrorToElements = (
    error: unknown
): JSX.Element | JSX.Element[] => {
    if (typeof error === 'string') {
        return <div>{error}</div>;
    }

    if (error instanceof RequestError) {
        return convertErrorToElements(error.data);
    }

    if (error instanceof AxiosError) {
        return convertErrorToElements(error.response?.data);
    }

    if (error instanceof Error) {
        // TRPCError is an instance of Error
        if (error instanceof TRPCError) {
            return (
                <div>
                    <div>
                        <b>{error.code}</b>
                    </div>
                    <div>{error.message}</div>
                </div>
            );
        }
        return <div>{error.message}</div>;
    }

    if (isJsonApiError(error)) {
        const errors = error.errors;

        if (errors.length === 1) {
            return <div>{errors[0].detail || errors[0].title}</div>;
        }

        return errors.map((item) => (
            <div key={item.detail || item.title}>
                • {item.detail || item.title}
            </div>
        ));
    }

    return <div>An unknown error occurred.</div>;
};

const isJsonApiError = (error: unknown): error is ResponseWithErrors => {
    return (
        typeof error === 'object' &&
        error !== null &&
        'errors' in error &&
        Array.isArray(error.errors)
    );
};
