import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'focus-visible/dist/focus-visible';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import { AppProvider } from './app/app-provider';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://b4b559736cd84948aca87bc827f1aef9@o368177.ingest.sentry.io/5954331',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.APP_ENV,
        allowUrls: [
            /^http:\/\/localhost:4203/,
            /^https:\/\/feedr(-(alpha|beta|dev))?\.gannettdigital\.com/,
        ],
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <AppProvider>
        <App />
    </AppProvider>
);
