import type { SystemStyleObject } from '@chakra-ui/react';

export const makeScrollbarStyles = (
    color: string,
    bgColor: string
): SystemStyleObject => {
    return {
        scrollbarColor: `${color} ${bgColor}`,
        '&::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
            display: 'block',
            backgroundColor: bgColor,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: color,
            border: '2px solid',
            borderColor: bgColor,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: color,
        },
    };
};
