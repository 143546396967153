import { memo, useEffect } from 'react';

export interface PageTitleProps {
    title: string;
}

// update document title as name change
const PageTitleCom = ({ title }: PageTitleProps) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return null;
};

export const PageTitle = memo(PageTitleCom);
