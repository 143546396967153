import { z } from 'zod';

export const userResourceIdentifierValidator = z.object({
    type: z.literal('users'),
    id: z.string().min(1),
});

export type UserResourceIdentifier = z.infer<
    typeof userResourceIdentifierValidator
>;

export const userResourceObjectValidator =
    userResourceIdentifierValidator.extend({
        attributes: z.object({
            email: z.string().email(),
            firstName: z.string().min(1),
            lastName: z.string().min(1),
        }),
    });

export type UserResourceObject = z.infer<typeof userResourceObjectValidator>;
