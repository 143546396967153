import type {
    ColorMode,
    SystemStyleObject,
    ThemeOverride,
    ComponentStyleConfig,
} from '@chakra-ui/react';
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

import { makeScrollbarStyles } from '@dmp/shared/client-utils';
import { darken, lighten } from 'polished';
import { makeButton } from './components/make-button';

/**
 * Note: Chakras theme config does not allow base colors to be changed between dark/light mode.
 */
export interface BaseColors {
    // in HEX, RGBA, HSL
    primary: string;
    // in HEX, RGBA, HSL - Optional secondary color
    secondary?: string;
    // in HEX, RGBA, HSL - The overall color shade at 500, affects the app's overall color. Default: "gray.500"
    gray500?: string;
}

export interface MakeTheme {
    baseColors: BaseColors;
    // additional theme component configs
    componentConfigs?: Record<string, ComponentStyleConfig>;
    // additional styles placed in global
    globalStyles?: (colorMode: ColorMode) => SystemStyleObject;
    // darken level for primary/secondary color in dark mode to bring out the light text.
    // Default: 0.2. against the background, visual changes becomes minimal.
    darkModeDarkenLevel?: number;
    // define font family for heading, body, mono..
    fonts?: ThemeOverride['fonts'];
}

export const makeTheme = ({
    baseColors,
    componentConfigs = {},
    globalStyles,
    darkModeDarkenLevel = 0.2,
    fonts = {},
}: MakeTheme) => {
    const { gray500, primary, secondary = primary } = baseColors;

    const primaryShades = shade(primary);
    const colorShades = {
        gray: gray500 ? shade(gray500) : baseTheme.colors.gray,
        brand: primaryShades,
        primary: primaryShades,
        secondary: secondary ? shade(secondary) : primaryShades,
    };

    const overrides: ThemeOverride = {
        colors: colorShades,
        styles: {
            // default global styles
            global: ({ colorMode }) => ({
                body: {
                    background: mode(colorMode)('gray.50', 'gray.900'),
                },
                '.scrollbar, .scroll-bar, .scrollBar': makeScrollbar(colorMode),
                '.chakra-button.edged': {
                    border: '1px solid',
                    borderColor: mode(colorMode)(
                        'blackAlpha.200',
                        'blackAlpha.600'
                    ),
                },
                '.chakra-button.hover-lifted, .chakra-button.hover-lifted-lg': {
                    transition: 'all 0.15s ease-out',
                },
                '.chakra-button.hover-lifted:hover': {
                    boxShadow: 'md',
                },
                '.chakra-button.hover-lifted-lg:hover': {
                    boxShadow: 'lg',
                },
                '.chakra-button.hover-primary:hover': {
                    backgroundColor: mode(colorMode)(
                        primary,
                        darken(darkModeDarkenLevel, primary)
                    ),
                },
                '.chakra-button.hover-secondary:hover': {
                    backgroundColor: mode(colorMode)(
                        secondary,
                        darken(darkModeDarkenLevel, secondary)
                    ),
                },
                ...(globalStyles?.(colorMode) ?? {}),
            }),
        },
        components: {
            Button: makeButton(primary, secondary, darkModeDarkenLevel),
            ...componentConfigs,
        },
        fonts,
    };

    return {
        theme: extendTheme(overrides),
        createColorShades: shade,
        colorShades,
    };
};

const makeScrollbar = (colorMode: ColorMode): SystemStyleObject => {
    const color = mode(colorMode)('blackAlpha.200', 'blackAlpha.400');
    const bg = mode(colorMode)('gray.50', 'gray.600');
    return makeScrollbarStyles(color, bg);
};

const mode =
    (colorMode: ColorMode) =>
    (light: string, dark: string): string =>
        colorMode === 'light' ? light : dark;

/**
 * Generate color shades for Chakra UI
 */
const shade = (color: string) => ({
    50: lighten(0.48, color),
    100: lighten(0.46, color),
    200: lighten(0.36, color),
    300: lighten(0.22, color),
    400: lighten(0.08, color),
    500: color,
    600: darken(0.08, color),
    700: darken(0.22, color),
    800: darken(0.36, color),
    900: darken(0.46, color),
});
