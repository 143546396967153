import { z } from 'zod';
import type { FilterDefinition, FilterDefinitions } from './types';
import {
    tryToBool,
    tryToStringArray,
    tryToString,
} from '@dmp/shared/zod-utils';

/**
 * Narrow the types of a filter definition. Ensures, for example that in `{ type: 'boolean' }`,
 * `boolean` is interpreted as a string literal and not a generic string.
 */
export function filters<T extends FilterDefinitions>(filters: T): T {
    return filters;
}

export function zodTypeFromFilter(filter: FilterDefinition) {
    switch (filter.type) {
        case 'boolean':
            return z.preprocess(tryToBool, z.boolean());

        case 'in':
            return z.preprocess(tryToStringArray, z.array(z.string()));

        default:
            return z.preprocess(tryToString, z.string());
    }
}
