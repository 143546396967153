import { ApiUriBuilder } from './api-uri-builder';

export interface FiltersAndPagination {
    page?: {
        number?: number;
        size?: number;
    };
    filters?: Record<string, string | undefined>;
    other?: Record<string, string>;
}

/**
 * Parse a query string into it's Pagination and Filter
 * components. Throw out any other query parameters.
 */
export const queryStringToFiltersAndPagination = (
    searchQuery: string
): FiltersAndPagination => {
    const searchParams = new URLSearchParams(searchQuery);

    return Array.from(searchParams.entries()).reduce(
        (carrier, [key, value]) => {
            const filterMatch = /filter\[([^\]]+)\]/.exec(key);

            if (key === 'page[number]') {
                return {
                    ...carrier,
                    page: { ...carrier?.page, number: +value },
                };
            } else if (key === 'page[size]') {
                return {
                    ...carrier,
                    page: { ...carrier?.page, size: +value },
                };
            } else if (filterMatch) {
                return {
                    ...carrier,
                    filters: { ...carrier?.filters, [filterMatch[1]]: value },
                };
            }

            return {
                ...carrier,
                other: { ...carrier?.other, [key]: value },
            };
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as Record<string, any>
    );
};

export const filtersAndPaginationToQueryString = (
    filtersAndPagination: FiltersAndPagination
): string => {
    const uriBuilder = new ApiUriBuilder();
    const { page, filters } = filtersAndPagination;

    const queryString = uriBuilder.resolve('dummy', {
        page: {
            number: page && page.number,
            size: page && page.size,
        },
        filter: filters,
    });

    return queryString.split('?')[1];
};
