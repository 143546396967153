/**
 * Determine the absolute URL for a given path.
 * This includes the clients protocol, domain, port, etc.
 */
export function absoluteUrl(path: string): string {
    const cleanPath = path.replace(/^\/+/, '').replace(/\/+$/, '');
    const base =
        typeof window === 'undefined'
            ? 'http://localhost'
            : `${window.location.protocol}//${window.location.host}`;

    return `${base}/${cleanPath}`;
}

/**
 * Get api base url from current base URL
 * - `feedr-beta` -> `feedr-api-beta`
 * - `feedr` -> `feedr-api`
 */
export function clientUrlToApiUrl(url: string) {
    if (/-api(-dev|-beta)?/.test(url)) {
        return url;
    }

    return url.replace(
        /([A-Za-z]+)(-dev|-beta)?\.gannettdigital\.com/,
        '$1-api$2.gannettdigital.com'
    );
}
