/**
 * Convert Date to format m/d/Y
 *
 * @param {String} dateString - string of date
 * @param {String} separator - default is '/'
 *
 * @return {String} formated date - m/d/Y, if not date, return the original
 */
export const dateToMDY = (dateString: string, separator: string = '/') => {
    if (!isDate(dateString)) return dateString;

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = addZero(date.getMonth() + 1);
    const day = addZero(date.getUTCDate());
    return `${month}${separator}${day}${separator}${year}`;
};

// add leading zero
const addZero = (str: number) => `0${str}`.slice(-2);

const isDate = (dateString: string) => {
    const date = new Date(dateString);
    return !(!(date instanceof Date) || isNaN(date.valueOf()));
};
