import { Global, css } from '@emotion/react';

import 'react-calendar/dist/Calendar.css';

/**
 * Component with Global style to adjust original styling in react-calendar
 */
export function InputDateTimeStyles() {
    return <Global styles={styles} />;
}

const styles = css`
    .react-calendar {
        border: none;
        color: inherit;
        background: inherit;
        width: 100%;

        abbr[title] {
            text-decoration: none;
        }

        .react-calendar__navigation {
            height: 30px;

            button:hover {
                background: var(--chakra-colors-blue-500) !important;
                color: white !important;
                border-radius: 4px;
            }
        }

        .react-calendar__navigation__label {
            font-weight: bold;
            font-size: 18px;
        }

        .react-calendar__navigation__arrow {
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                background: transparent;
                opacity: 0.3;
            }
        }

        .react-calendar__tile {
            border: 1px solid;
            border-color: inherit;
            font-size: var(--chakra-fontSizes-sm);

            &:hover {
                background: var(--chakra-colors-blackAlpha-200);
            }

            abbr {
                user-select: none;
            }

            &:disabled {
                cursor: not-allowed;
                background: transparent;

                abbr {
                    opacity: 0.3;
                }
            }
        }

        .react-calendar__tile--now {
            color: var(--chakra-colors-blue-500);
            background: inherit;
            font-weight: bold;
        }

        .react-calendar__tile--active {
            background: var(--chakra-colors-blue-500) !important;
            color: white !important;
            font-weight: bold;
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            abbr {
                opacity: 0.4;
            }
        }

        .react-calendar__month-view__days__day--weekend {
            color: var(--chakra-colors-red-400);
        }

        // Collapse overlapped border between tiles nicely
        .react-calendar__month-view__days {
            width: calc(100% + 7px);

            .react-calendar__tile {
                margin: -1px 0 0 -1px;
            }
        }

        &.disabled {
            opacity: 0.6;
            .react-calendar__tile,
            abbr {
                cursor: not-allowed !important;
            }
        }
    }
`;
