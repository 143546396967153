import { tryToBool, tryToNumber, tryToString } from '@dmp/shared/zod-utils';
import { z } from 'zod';
import type { EntriesData, EntryData } from '..';
import { defaultValueForColumnType } from './defaults';
import { columnTypeValidator } from './misc';
import type { ColumnType, FeedSchema } from './types';

/**
 * Validate the structure of a FeedSchema.
 */
export const feedSchemaValidator = z.array(
    z.object({
        name: z.string(),
        type: columnTypeValidator(),
    })
);

/**
 * Create a Validator based on a FeedSchema. This can be used to validate EntryData.
 */
export function feedSchemaToEntryDataValidator<F extends FeedSchema>(
    schema: F
): z.ZodType<EntryData> {
    return z.object(
        schema.reduce((carrier, columnSchema) => {
            return {
                ...carrier,
                [columnSchema.name]: columnTypeToValidator(columnSchema.type),
            };
        }, {})
    );
}

/**
 * Create a Validator based on a FeedSchema. This can be used to validate EntryData.
 */
export const feedSchemaToEntriesDataValidator = (
    schema: FeedSchema
): z.ZodType<EntriesData> => {
    return z.array(feedSchemaToEntryDataValidator(schema));
};

/**
 * Convert a ColumnType into a Validator.
 */
export function columnTypeToValidator(columnType: ColumnType) {
    switch (columnType) {
        case 'boolean':
            return z
                .preprocess(tryToBool, z.boolean())
                .default(defaultValueForColumnType('boolean'));

        case 'number':
            return z
                .preprocess(tryToNumber, z.number())
                .default(defaultValueForColumnType('number'));

        case 'string':
            return z
                .preprocess(tryToString, z.string())
                .default(defaultValueForColumnType('string'));

        case 'url':
            return z
                .preprocess(tryToString, z.string().url())
                .default(defaultValueForColumnType('url'));
    }
}
