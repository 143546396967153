/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AtLeastOne, AtLeastTwo } from '@dmp/shared/types';
import { tryToNumber, tryToStringArray } from '@dmp/shared/zod-utils';
import { z } from 'zod';
import { preprocessQuery, preprocessSort } from './preprocessors';
import type {
    FilterDefinitions,
    FiltersToZodShape,
    JsonApiQueryDef,
} from './types';
import { zodTypeFromFilter } from './utils';

export interface PaginateOptions {
    maxSize?: number;
    defaultSize?: number;
}

export class JsonApiQuery<
    T extends z.ZodRawShape,
    F extends FilterDefinitions
> extends z.ZodType<
    z.objectOutputType<T, z.ZodTypeAny>,
    JsonApiQueryDef<T, F>
> {
    static create() {
        return new JsonApiQuery({
            typeName: 'JsonApiQuery',
            shape: {
                fields: z
                    .record(z.preprocess(tryToStringArray, z.array(z.string())))
                    .optional(),
            },
            filters: {},
        });
    }

    public filter<F extends FilterDefinitions>(filters: F) {
        const filterShape: FiltersToZodShape<F> = Object.entries(
            filters
        ).reduce((carrier, [name, filter]) => {
            const zodType = zodTypeFromFilter(filter);

            return {
                ...carrier,
                [name]: zodType,
            };
        }, {} as any);

        return new JsonApiQuery({
            ...this._def,
            shape: {
                ...this._def.shape,
                filter: z.object(filterShape).partial().optional(),
            },
            filters,
        });
    }

    public include<I extends string>(includable: [I, ...I[]]) {
        const literals = includable.map((i) => z.literal(i));

        const includeSchema =
            literals.length > 1
                ? z.union(
                      literals as [
                          z.ZodLiteral<I>,
                          z.ZodLiteral<I>,
                          ...z.ZodLiteral<I>[]
                      ]
                  )
                : literals[0];

        return new JsonApiQuery({
            ...this._def,
            shape: {
                ...this._def.shape,
                include: z
                    .preprocess(tryToStringArray, z.array(includeSchema))
                    .optional(),
            },
        });
    }

    sort<S extends string>(sortable: AtLeastOne<S>) {
        const literals = sortable.map((s) => z.literal(s));

        return new JsonApiQuery({
            ...this._def,
            shape: {
                ...this._def.shape,
                sort: z
                    .preprocess(
                        preprocessSort,
                        z.array(
                            z.object({
                                field:
                                    literals.length === 1
                                        ? literals[0]
                                        : z.union(
                                              literals as AtLeastTwo<
                                                  z.ZodLiteral<S>
                                              >
                                          ),
                                direction: z.union([
                                    z.literal('ASC'),
                                    z.literal('DESC'),
                                ]),
                            })
                        )
                    )
                    .optional(),
            },
        });
    }

    paginate(options: PaginateOptions = {}) {
        const { maxSize = 50, defaultSize = 25 } = options;

        return new JsonApiQuery({
            ...this._def,
            shape: {
                ...this._def.shape,
                page: z
                    .object({
                        number: z.preprocess(
                            tryToNumber,
                            z.number().positive().default(1)
                        ),
                        size: z.preprocess(
                            tryToNumber,
                            z
                                .number()
                                .positive()
                                .max(maxSize)
                                .default(defaultSize)
                        ),
                    })
                    .default({ number: 1, size: defaultSize }),
            },
        });
    }

    get filters() {
        return this._def.filters;
    }

    _parse(
        input: z.ParseInput
    ): z.ParseReturnType<z.objectOutputType<T, z.ZodTypeAny>> {
        return z
            .preprocess(preprocessQuery, z.object(this._def.shape))
            ._parse(input);
    }
}

export const jsonApiQuery = JsonApiQuery.create;
