import { useRecoilCallback, useRecoilValue } from 'recoil';
import type { UserState } from '../state/user';
import {
    currentUserIdState,
    currentUserSelector,
    userItemState,
} from '../state/user';

export const useCurrentUser = () => {
    const currentUser = useRecoilValue(currentUserSelector);

    const setCurrentUser = useRecoilCallback(
        ({ set }) =>
            (user: UserState) => {
                set(userItemState(user.id), user);
                set(currentUserIdState, user.id);
            },
        []
    );

    return [currentUser, setCurrentUser] as const;
};
