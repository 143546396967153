import type { ParsedQs } from 'qs';
import { parse } from 'qs';
import { z } from 'zod';
import { InvalidQueryStringParameter } from './exceptions/InvalidQueryStringParameter';
import type { JsonApiQuery } from './types';

/**
 * Take a query string—or a query that has already been generally
 * parsed—and parse it into a format that can be used as a JSON:API request.
 */
export function parseQuery(query: unknown): JsonApiQuery {
    const result = parsedQs().parse(query);
    const { fields, filter, include, page, sort } = result;

    return {
        fields: processFields(fields),
        filter: processFilter(filter),
        include: processInclude(include),
        page: processPage(page),
        sort: processSort(sort),
    };
}

function parsedQs(): z.ZodEffects<
    z.ZodType<ParsedQs, z.ZodTypeDef, ParsedQs>,
    ParsedQs,
    ParsedQs
> {
    const pq: z.ZodType<ParsedQs> = z.lazy(() =>
        z.record(
            z.union([
                z.undefined(),
                z.string(),
                z.array(z.string()),
                pq,
                z.array(pq),
            ])
        )
    );

    return z.preprocess(
        (val) => (typeof val === 'string' ? parse(val) : val),
        pq
    );
}

function processFields(fields: ParsedQs[string]): JsonApiQuery['fields'] {
    if (typeof fields !== 'object') {
        return undefined;
    }

    return Object.entries(fields).reduce((carrier, [key, val]) => {
        if (typeof val !== 'string') {
            return carrier;
        }

        return {
            ...carrier,
            [key]: val.split(','),
        };
    }, {});
}

function processFilter(filter: ParsedQs[string]): JsonApiQuery['filter'] {
    if (typeof filter !== 'object' || Array.isArray(filter)) {
        return undefined;
    }

    return Object.entries(filter).reduce((carrier, [key, val]) => {
        if (typeof val !== 'string') {
            return carrier;
        }

        return {
            ...carrier,
            [key]: val,
        };
    }, {});
}

function processInclude(include: ParsedQs[string]): JsonApiQuery['include'] {
    if (include === undefined) {
        return undefined;
    }

    if (typeof include !== 'string') {
        throw new InvalidQueryStringParameter(
            'include',
            'Arrays are not supported for this field type.'
        );
    }

    return include.split(',');
}

function processPage(page: ParsedQs[string]): JsonApiQuery['page'] {
    const number =
        (typeof page === 'object' &&
            'number' in page &&
            typeof page['number'] === 'string' &&
            parseInt(page['number'])) ||
        1;

    const size =
        (typeof page === 'object' &&
            'size' in page &&
            typeof page['size'] === 'string' &&
            parseInt(page['size'])) ||
        25;

    return {
        number,
        size,
    };
}

function processSort(sort: ParsedQs[string]): JsonApiQuery['sort'] {
    if (sort === undefined) {
        return undefined;
    }

    if (typeof sort !== 'string') {
        throw new InvalidQueryStringParameter(
            'sort',
            'Arrays are not supported for this field type.'
        );
    }

    return sort.split(',').map((originalValue) => {
        const field =
            typeof originalValue === 'string'
                ? originalValue.replace(/^-/, '')
                : originalValue;

        const direction =
            typeof originalValue === 'string'
                ? originalValue.startsWith('-')
                    ? 'DESC'
                    : 'ASC'
                : 'ASC';

        return { field, direction };
    });
}
