/**
 * count sequence of numbers from start number
 * start: 2, totalNum: 2 => [2, 3]
 * start: 4, totalNum: 3 => [4, 5, 6]
 */
export const countSeq = (start: number, totalNum: number, max?: number) => {
    const seq = [start];
    if (totalNum <= 1) {
        return seq;
    }

    let n = 1;
    while (n < totalNum) {
        seq.push(start + n);
        n += 1;
    }

    return max ? seq.filter((i) => i <= max) : seq;
};

export default countSeq;
