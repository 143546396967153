import { filterMode } from '@dmp/feedr/models';
import { tryToNumber } from '@dmp/shared/zod-utils';
import { z } from 'zod';

export const feedsConsumeQueryValidator = z.object({
    filterMode: filterMode().default('all'),
    filter: z.record(z.any()).optional(),
    limit: z
        .preprocess(tryToNumber, z.number().int().max(500).positive())
        .optional(),
});

export type FeedsConsumeQuery = z.infer<typeof feedsConsumeQueryValidator>;
