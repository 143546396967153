/**
 * @file These functions attempt to coerce input values into the desired output value.
 * Their output is not strongly typed, as it is meant to be passed to a validator. These
 * are useful, for example, when parsing query strings, which are always string values.
 */

/**
 * Attempt to coerce a value into a `boolean`.
 * If it cannot be done, return the value as-is.
 */
export function tryToBool(val: unknown) {
    switch (typeof val) {
        case 'bigint':
        case 'number':
            return val > 0;

        case 'string':
            if (['true', '1'].includes(val)) {
                return true;
            } else if (['false', '0'].includes(val)) {
                return false;
            } else {
                return val;
            }

        default:
            return val;
    }
}

/**
 * Attempt to coerce a value into a `number`.
 * If it cannot be done, return the value as-is.
 */
export function tryToNumber(val: unknown) {
    switch (typeof val) {
        case 'string':
            // Number() will convert an empty string to `0`,
            // but we don't consider that valid for our logic.
            return val === '' ? val : Number(val);

        case 'boolean':
            return Number(val);

        default:
            return val;
    }
}

/**
 * If the provided value is a string, attempt to convert it
 * to a Date. Otherwise, return the value as-is.
 */
export function tryToDate(val: unknown) {
    switch (typeof val) {
        case 'string':
            return new Date(val);

        default:
            return val;
    }
}

/**
 * Attempt to coerce a value into a `string`.
 * If it cannot be done, return the value as-is.
 */
export function tryToString(val: unknown) {
    if (val instanceof Date) {
        return val.toISOString();
    }

    switch (typeof val) {
        case 'bigint':
        case 'number':
        case 'boolean':
            return val.toString();

        default:
            return val;
    }
}

/**
 * Supports string arrays that may be represented
 * by a comma-separated string (ie. `a,b,c`).
 */
export function tryToStringArray(val: unknown) {
    if (Array.isArray(val)) {
        return val;
    }

    switch (typeof val) {
        case 'string':
            return val.split(',').filter((e) => e);

        default:
            return val;
    }
}

/**
 * Attempt to coerce a value from a `string` that is base64 encoded to a cleartext `string`.
 * If it cannot be done, return the value as-is.
 */
export function tryToStringFromBase64(val: unknown) {
    const base64 = tryToString(val);

    if (typeof base64 !== 'string') {
        return val;
    }

    return Buffer.from(base64, 'base64').toString('utf-8');
}

/**
 * Recursively converts all empty strings to undefined.
 */
export const emptyStringsAsUndefined = (value: unknown): unknown => {
    if (typeof value === 'string' && value === '') {
        return undefined;
    }

    if (Array.isArray(value)) {
        return value.map(emptyStringsAsUndefined);
    }

    if (typeof value === 'object' && value !== null) {
        return Object.fromEntries(
            Object.entries(value).map(([key, value]) => [
                key,
                emptyStringsAsUndefined(value),
            ])
        );
    }

    return value;
};
