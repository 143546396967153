import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGate from './components/common/auth-gate';
import FullScreenLoader from './components/common/full-screen-loader';

const FeedListView = React.lazy(
    () => import('./components/feed-list-view/feed-list-view')
);

const FeedView = React.lazy(() => import('./components/feed-view'));
const Error404 = React.lazy(() => import('./components/404'));

export function App() {
    return (
        <AuthGate>
            <React.Suspense fallback={<FullScreenLoader />}>
                <Routes>
                    <Route path="/" element={<FeedListView />} />
                    <Route path="/feeds/:feedId" element={<FeedView />} />
                    <Route element={<Error404 />} />
                </Routes>
            </React.Suspense>
        </AuthGate>
    );
}

export default App;
