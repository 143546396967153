import { fatch, ApiUriBuilder } from '@dmp/shared/client-utils';
import type { OrderDirection } from '@dmp/shared/json-api-request';
import type {
    CreateEntryRequest,
    CreateFeedRequest,
    EntriesQuery,
    FeedsConsumeResponse,
    FeedsQuery,
    ListFeedsResponse,
    PaginateEntriesResponse,
    ShowEntryResponse,
    ShowFeedResponse,
    ShowUserResponse,
    UpdateEntryRequest,
    UpdateFeedRequest,
} from './api-validators';

/****************************************************************
 * FEEDS
 ****************************************************************/

const apiUriBuilder = new ApiUriBuilder();

export function fetchFeedList(
    searchKeyword: string,
    pageNumber: number,
    pageSize: number
) {
    return fatch<ListFeedsResponse>(
        apiUriBuilder.resolve<FeedsQuery>('feeds', {
            fields: {
                feeds: ['name', 'updatedAt'],
            },
            filter: {
                combined: searchKeyword,
            },
            page: {
                number: pageNumber,
                size: pageSize,
            },
            sort: [
                {
                    field: 'updatedAt',
                    direction: 'DESC',
                },
            ],
            include: ['updatedBy'],
        })
    );
}

export const fetchFeed = (feedId: string) =>
    fatch<ShowFeedResponse>(apiUriBuilder.resolve(`feeds/${feedId}`));

export const postNewFeed = (payload: CreateFeedRequest) =>
    fatch<ShowFeedResponse>(apiUriBuilder.resolve('feeds'), {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const patchFeed = (feedId: string, payload: UpdateFeedRequest) =>
    fatch<ShowFeedResponse>(apiUriBuilder.resolve(`feeds/${feedId}`), {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
    });

/****************************************************************
 * ENTRIES
 ****************************************************************/
export const fetchEntries = (
    feedId: string,
    pageNumber: number = 1,
    searchKeyword: string = '',
    sortField: string = 'updatedAt',
    sortBy?: OrderDirection
) => {
    const includeSort =
        sortBy && sortField
            ? {
                  sort: [
                      {
                          field: sortField,
                          direction: sortBy,
                      },
                  ],
              }
            : {};

    return fatch<PaginateEntriesResponse>(
        apiUriBuilder.resolve<EntriesQuery>(`feeds/${feedId}/entries`, {
            filter: {
                data: searchKeyword,
            },
            page: {
                number: pageNumber,
                size: 50,
            },
            ...includeSort,
        })
    );
};

export const postNewEntry = (feedId: string, payload: CreateEntryRequest) =>
    fatch<ShowEntryResponse>(apiUriBuilder.resolve(`feeds/${feedId}/entries`), {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const deleteEntry = (entryId: string) =>
    fatch<void>(apiUriBuilder.resolve(`entries/${entryId}`), {
        method: 'DELETE',
    });

export const patchEntry = (entryId: string, payload: UpdateEntryRequest) =>
    fatch<ShowEntryResponse>(apiUriBuilder.resolve(`entries/${entryId}`), {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
    });

/****************************************************************
 * USERS
 ****************************************************************/

export const fetchCurrentUser = () =>
    fatch<ShowUserResponse>(apiUriBuilder.resolve('users/current'));

/****************************************************************
 * IMPORT CSV/TSV
 ****************************************************************/

export const patchImportCSV = (feedId: string, file: File) => {
    const payload = new FormData();
    payload.append('file', file);

    return fatch<ShowFeedResponse>(
        apiUriBuilder.resolve(`feeds/${feedId}/import`),
        {
            method: 'PATCH',
            body: payload,
        }
    );
};

/****************************************************************
 * CONSUME FEED DATA
 ****************************************************************/

export const fetchFeedDataConsume = (url: string) => {
    return fatch<FeedsConsumeResponse>(url);
};
