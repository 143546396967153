import type * as React from 'react';
import { useToast } from '@chakra-ui/react';

export const useSuccessToaster = () => {
    const toast = useToast();

    const handleSuccess = (msg: React.ReactNode, duration: number = 3000) => {
        toast({
            title: 'Success',
            status: 'success',
            duration,
            isClosable: true,
            description: msg,
        });
    };

    return {
        handleSuccess,
    };
};
