import { pagination } from '@dmp/shared/zod-utils';
import { z } from 'zod';
import { entryResourceObjectValidator } from './entries-resource-objects';

export const showEntryResponseValidator = z.object({
    data: entryResourceObjectValidator,
});

export type ShowEntryResponse = z.infer<typeof showEntryResponseValidator>;

export const listEntriesResponseValidator = z.object({
    data: z.array(entryResourceObjectValidator),
});

export type ListEntriesResponse = z.infer<typeof listEntriesResponseValidator>;

export const paginateEntriesResponseValidator =
    listEntriesResponseValidator.extend({
        meta: z.object({
            pagination: pagination,
        }),
    });

export type PaginateEntriesResponse = z.infer<
    typeof paginateEntriesResponseValidator
>;
