import type { ColumnType } from './types';

export type ColumnTypeToTSType<T extends ColumnType> = T extends 'url'
    ? string
    : T extends 'string'
    ? string
    : T extends 'number'
    ? number
    : T extends 'boolean'
    ? boolean
    : never;

export const defaultValueForColumnType = <T extends ColumnType>(
    columnType: T
): ColumnTypeToTSType<T> => {
    switch (columnType) {
        case 'url':
        case 'string':
            // @ts-ignore Sad TS.
            return '';

        case 'number':
            // @ts-ignore Sad TS.
            return 0;

        case 'boolean':
            // @ts-ignore Sad TS.
            return false;

        default:
            throw new Error(`Unsupported column type: ${columnType}.`);
    }
};
