import * as React from 'react'; // note: import react here to have it available to render in some tests (qqms-client > permission-gate.spec.ts)
import type { AlertProps } from '@chakra-ui/react';
import { Alert, AlertIcon } from '@chakra-ui/react';

export type AlertBoxProps = AlertProps;

export const AlertBox = ({
    children,
    status = 'info',
    ...rest
}: AlertBoxProps) => {
    return (
        <Alert status={status} {...rest}>
            <AlertIcon />
            {children}
        </Alert>
    );
};
